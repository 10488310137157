/**
 * @author Mathias MANOU
 * @email mathiasmanou@gmail.com
 * @description Confidentialité page
 */

import { Flex, Text } from "@chakra-ui/react";

import AnimatedPage from "../components/AnimatedPage";

const Confident = () => {
  return (
    <AnimatedPage>
      <Flex flex={1} alignItems={"center"} justifyContent={"center"}>
        <Text color={"brand.100"} pt={"2.5em"} fontSize={"1.3em"}>
          Les informations recueillies sur ce formulaire sont enregistrées dans
          un fichier informatisé ayant pour contact Stéphane VROT. La base
          légale du traitement est le consentement.<br></br>
          {/* <br></br> Les données collectées seront communiquées aux seuls
          destinataires suivants : [destinataires des données].<br></br> */}
          <br></br> Les données sont conservées pendant 1 an.<br></br>
          <br></br> Vous pouvez accéder aux données vous concernant, les
          rectifier, demander leur effacement ou exercer votre droit à la
          limitation du traitement de vos données. (Vous pouvez retirer à tout
          moment votre consentement au traitement de vos données ; Vous pouvez
          également vous opposer au traitement de vos données ; Vous pouvez
          également exercer votre droit à la portabilité de vos données)
          <br></br>
          <br></br>Consultez le site cnil.fr pour plus d’informations sur vos
          droits.<br></br>
          <br></br> Pour exercer ces droits ou pour toute question sur le
          traitement de vos données dans ce dispositif, vous pouvez contacter
          (le cas échéant, notre délégué à la protection des données ou le
          service chargé de l’exercice de ces droits) :
          stephanevrot@aibancassurance.com<br></br>
          <br></br> Si vous estimez, après nous avoir contactés, que vos droits
          « Informatique et Libertés » ne sont pas respectés, vous pouvez
          adresser une réclamation à la CNIL.<br></br>
          <br></br> N.B : distinguer dans le formulaire de collecte, par exemple
          via des astérisques, les données dont la fourniture est obligatoire de
          celles dont la fourniture est facultative et précisez les conséquences
          éventuelles en cas de non-fourniture des données.
        </Text>
      </Flex>
    </AnimatedPage>
  );
};

export default Confident;
