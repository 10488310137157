// AnimatedPage.tsx

import { Box } from "@chakra-ui/react";
import { motion } from "framer-motion";

const MotionBox = motion(Box);

const pageVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

const pageTransition = {
  type: "tween",
  ease: "easeInOut",
  duration: 1,
};

type Props = JSX.Element | JSX.Element[] | string | string[];

const AnimatedPage = ({ children }: { children: Props }) => {
  return (
    <MotionBox
      h={"100%"}
      variants={pageVariants}
      initial="initial"
      animate="animate"
      exit="exit"
      transition={pageTransition}
      w={"100%"}
      // bg={"blue.200"}
    >
      {children}
    </MotionBox>
  );
};

export default AnimatedPage;
