import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
} from "@chakra-ui/react";
import { useContext } from "react";
import { AppContext } from "../helpers/authProvider";

function Modale() {
  const { state, dispatch } = useContext(AppContext);
  const { modalOpen, modalData } = state;

  const toggleModal = () => {
    dispatch({ type: "MODAL", payload: !modalOpen });
  };

  return (
    <Modal size={"xl"} isOpen={modalOpen} onClose={toggleModal} isCentered>
      <ModalOverlay />
      <ModalContent bg={"brand.500"}>
        <ModalHeader color={"brand.300"}>{modalData.title}</ModalHeader>
        <ModalCloseButton color={"brand.300"} />
        <ModalBody>
          {modalData?.data.map((libelle: string, index: number) => {
            // console.log({ modalData });
            return (
              <Text key={index} color={"brand.100"}>
                {libelle}
              </Text>
            );
          })}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default Modale;
