// SearchPage.tsx

import {
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { useState } from "react";
import AnimatedPage from "./AnimatedPage";
import { CloseIcon } from "@chakra-ui/icons";
import { useLocation } from "react-router-dom";

// Interface des props pour SearchPage
interface FilterBarProps {
  data: any[];
  children: (filteredData: any[]) => React.ReactElement;
}

const FilterBar: React.FC<FilterBarProps> = ({ data, children }) => {
  // État pour la chaîne de recherche et les données filtrées
  const [query, setQuery] = useState("");
  const [filteredData, setFilteredData] = useState(data);
  const location = useLocation();
  const { pathname } = location;
  // console.log({ pathname });

  const street = pathname.includes("/intervenants")
    ? "Rechercher..."
    : "Rechercher parmi nos thématiques la formation à contextualiser à votre besoin";
  // Fonction pour filtrer les données
  const filterData = (query: string) => {
    if (query === "") {
      setFilteredData(data);
    } else {
      const lowerQuery = query.toLowerCase();
      const result = data.filter((item: any) =>
        Object.values(item).some((value) =>
          String(value).toLowerCase().includes(lowerQuery)
        )
      );
      setFilteredData(result);
    }
  };

  const reset = () => {
    setQuery("");
    setFilteredData(data);
  };

  // Gérer les changements dans la barre de recherche
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setQuery(value);
    filterData(value);
  };

  return (
    <AnimatedPage>
      <Flex
        direction={"column"}
        bg={"brand.400"}
        as="header"
        top="0"
        mx={4}
        gap={"1em"}
        alignItems={"center"}
        justifyContent={"center"}>
        <InputGroup>
          <Input
            placeholder={street}
            value={query}
            onChange={handleSearchChange}
            focusBorderColor="brand.200"
            color={"brand.200"}
          />
          <InputRightElement width="4.5rem">
            {query.length > 0 ? (
              <IconButton
                onClick={reset}
                variant="ghost"
                // colorScheme="brand"
                color={"brand.100"}
                aria-label="Clear input"
                icon={<CloseIcon />}
              />
            ) : null}
          </InputRightElement>
        </InputGroup>
      </Flex>
      {/* Passer filteredData au children */}
      {children(filteredData)}
    </AnimatedPage>
  );
};

export default FilterBar;
