/**
 * @author Mathias MANOU
 * @email mathiasmanou@gmail.com
 * @description App router
 */

import { Route, Routes } from "react-router-dom";

import Root from "./components/root";
import Home from "./pages/Home";
import Coaching from "./pages/Coaching";
import Contact from "./pages/Contact";
import Confident from "./pages/Confident";
import FormationsCertifiantes from "./pages/FormationsCertifiantes";
import Intervenants from "./pages/Intervenants";

const App = () => {
  return (
    <Routes>
      {/* Route principale avec un layout commun */}
      <Route path="/" element={<Root />}>
        <Route path="/" element={<Home />} />
        <Route
          path="formationscertifiantes"
          element={<FormationsCertifiantes />}
        />
        <Route path="intervenants" element={<Intervenants />} />
        <Route path="coaching" element={<Coaching />} />
        <Route path="contact" element={<Contact />} />
        <Route path="politiquedeconfidentialité" element={<Confident />} />
      </Route>
    </Routes>
  );
};
export default App;

// code promo axeptio viensonsereaime20
