/**
 * @author Mathias MANOU
 * @email mathiasmanou@gmail.com
 * @description Contact page
 */

import {
  Button,
  Flex,
  Textarea,
  FormControl,
  FormLabel,
  Text,
  Input,
  useToast,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { motion } from "framer-motion";
import AnimatedPage from "../components/AnimatedPage";

const MotionButton = motion(Button);

const contactUrl = "https://sendemail-lz32gweh2q-uc.a.run.app";

const schemaContact = yup.object().shape({
  emailpro: yup.string().email("Email invalide").required("Email requis"),
  emailperso: yup.string().email("Email invalide"),
  firstname: yup.string().required("Prénom requis"),
  lastname: yup.string().required("Nom requis"),
  firm: yup.string().required("Entreprise requise"),
  poste: yup.string().required("Poste requis"),
  pays: yup.string().required("Pays requis"),
  phone: yup.number().required("Téléphone requis"),
  message: yup.string().required("Message requis"),
});

const Contact = () => {
  const fileInputRef = useRef<any>(null);
  // const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const toast = useToast();
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
    reset,
  } = useForm({ resolver: yupResolver(schemaContact) });

  const handleClick = () => {
    if (!fileInputRef.current) return;
    fileInputRef.current.click();
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    setFileName(file.name);
  };

  const onSubmit = async (data: any) => {
    // setIsLoading(true);
    // console.log({ data, selectedFile });
    const {
      emailperso,
      emailpro,
      firstname,
      lastname,
      message,
      firm,
      pays,
      phone,
    } = data;
    try {
      const formData = new FormData();
      formData.append("emailperso", emailperso);
      formData.append("emailpro", emailpro);
      formData.append("firstname", firstname);
      formData.append("lastname", lastname);
      formData.append("firm", firm);
      formData.append("pays", pays);
      formData.append("phone", phone);
      formData.append("message", message);

      if (selectedFile) {
        formData.append("file", selectedFile);
      }

      const response = await fetch(contactUrl, {
        method: "POST",
        body: formData,
      });
      // console.log(response);
      const retour = await response.text(); // Utilisez .json() si le serveur renvoie des JSON
      // console.log({ retour });

      if (response.ok) {
        toast({
          title: "Merci.",
          description: "Votre demande a été prise en compte.",
          status: "success",
          position: "top-left",
          duration: 9000,
          isClosable: true,
        });
        reset();
      }
      if (!response.ok) {
        // throw new Error("Network response was not ok");
        toast({
          title: "Désolé.",
          description: "Une erreur est survenue.",
          status: "error",
          position: "top-left",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <AnimatedPage>
      <Flex
        alignItems={"center"}
        flex={1}
        as="form"
        direction={"column"}
        onSubmit={handleSubmit(onSubmit)}
        method="POST"
      >
        <Flex
          direction={{ base: "column", md: "row" }}
          justifyContent={"space-around"}
          w={{ base: "90%", md: "80%" }}
        >
          <FormControl
            width={{ base: "100%", md: "40%" }}
            // mb={"1em"}
            isRequired
            isInvalid={errors.lastname ? true : false}
          >
            <FormLabel color={"brand.100"}>Nom</FormLabel>
            <Input
              _hover={{
                border: "1px solid",
                borderColor: "brand.200",
                color: "brand.100",
              }}
              _focus={{
                border: "1px solid",
                borderColor: "brand.200",
                color: "brand.100",
              }}
              required
              {...register("lastname")}
              bg={"brand.400"}
              color={"brand.100"}
              // border={"none"}
            />
            <Text
              visibility={
                dirtyFields.lastname && errors.lastname ? "visible" : "hidden"
              }
              color={"red.500"}
            >
              {errors.lastname ? errors.lastname.message : "errorPasswd"}
            </Text>
          </FormControl>

          <FormControl
            width={{ base: "100%", md: "40%" }}
            //mt={"1em"}
            // mb={"1em"}
            isRequired
            isInvalid={errors.firstname ? true : false}
          >
            <FormLabel color={"brand.100"}>Prénom</FormLabel>
            <Input
              _hover={{
                border: "1px solid",
                borderColor: "brand.200",
                color: "brand.100",
              }}
              _focus={{
                border: "1px solid",
                borderColor: "brand.200",
                color: "brand.100",
              }}
              {...register("firstname")}
              required
              bg={"brand.400"}
              color={"brand.100"}
            />
            <Text
              visibility={errors.firstname ? "visible" : "hidden"}
              color={"red.500"}
            >
              {errors.firstname
                ? errors.firstname.message
                : "errorConfirmPaswword"}
            </Text>
          </FormControl>
        </Flex>

        <Flex
          direction={{ base: "column", md: "row" }}
          justifyContent={"space-around"}
          w={{ base: "90%", md: "80%" }}
        >
          <FormControl
            width={{ base: "100%", md: "40%" }}
            isRequired
            isInvalid={errors.firm ? true : false}
          >
            <FormLabel color={"brand.100"}>Entreprise</FormLabel>
            <Input
              _hover={{
                border: "1px solid",
                borderColor: "brand.200",
                color: "brand.100",
              }}
              _focus={{
                border: "1px solid",
                borderColor: "brand.200",
                color: "brand.100",
              }}
              {...register("firm")}
              required
              bg={"brand.400"}
              color={"brand.100"}
            />
            <Text
              visibility={errors.firm ? "visible" : "hidden"}
              color={"red.500"}
            >
              {errors.firm ? errors.firm.message : "errorConfirmPaswword"}
            </Text>
          </FormControl>

          <FormControl
            width={{ base: "100%", md: "40%" }}
            isRequired
            isInvalid={errors.firm ? true : false}
          >
            <FormLabel color={"brand.100"}>Poste</FormLabel>
            <Input
              _hover={{
                border: "1px solid",
                borderColor: "brand.200",
                color: "brand.100",
              }}
              _focus={{
                border: "1px solid",
                borderColor: "brand.200",
                color: "brand.100",
              }}
              {...register("poste")}
              required
              bg={"brand.400"}
              color={"brand.100"}
            />
            <Text
              visibility={errors.poste ? "visible" : "hidden"}
              color={"red.500"}
            >
              {errors.poste ? errors.poste.message : "errorConfirmPaswword"}
            </Text>
          </FormControl>
        </Flex>

        <Flex
          direction={{ base: "column", md: "row" }}
          justifyContent={"space-around"}
          w={{ base: "90%", md: "80%" }}
        >
          <FormControl
            width={{ base: "100%", md: "40%" }}
            isRequired
            isInvalid={dirtyFields.emailpro && errors.emailpro ? true : false}
          >
            <FormLabel color={"brand.100"}>Email professionnel</FormLabel>
            <Input
              _hover={{
                border: "1px solid",
                borderColor: "brand.200",
                color: "brand.100",
              }}
              _focus={{
                border: "1px solid",
                borderColor: "brand.200",
                color: "brand.100",
              }}
              type="email"
              required
              {...register("emailpro")}
              bg={"brand.400"}
              color={"brand.100"}
            />
            <Text
              visibility={
                dirtyFields.emailpro && errors.emailpro ? "visible" : "hidden"
              }
              color={"red.500"}
            >
              {errors.emailpro ? errors.emailpro.message : "email"}
            </Text>
          </FormControl>

          <FormControl
            width={{ base: "100%", md: "40%" }}
            isInvalid={errors.emailperso ? true : false}
          >
            <FormLabel color={"brand.100"}>Email personnel</FormLabel>
            <Input
              _hover={{
                border: "1px solid",
                borderColor: "brand.200",
                color: "brand.100",
              }}
              _focus={{
                border: "1px solid",
                borderColor: "brand.200",
                color: "brand.100",
              }}
              type="email"
              required
              {...register("emailperso")}
              bg={"brand.400"}
              color={"brand.100"}
            />
            <Text
              visibility={errors.emailperso ? "visible" : "hidden"}
              color={"red.500"}
            >
              {errors.emailperso ? errors.emailperso.message : "email"}
            </Text>
          </FormControl>
        </Flex>

        <Flex
          direction={{ base: "column", md: "row" }}
          justifyContent={"space-around"}
          w={{ base: "90%", md: "80%" }}
        >
          <FormControl
            width={{ base: "100%", md: "40%" }}
            isRequired
            isInvalid={errors.pays ? true : false}
          >
            <FormLabel color={"brand.100"}>Pays de résidence</FormLabel>
            <Input
              _hover={{
                border: "1px solid",
                borderColor: "brand.200",
                color: "brand.100",
              }}
              _focus={{
                border: "1px solid",
                borderColor: "brand.200",
                color: "brand.100",
              }}
              {...register("pays")}
              required
              bg={"brand.400"}
              color={"brand.100"}
            />
            <Text
              visibility={errors.pays ? "visible" : "hidden"}
              color={"red.500"}
            >
              {errors.pays ? errors.pays.message : "errorConfirmPaswword"}
            </Text>
          </FormControl>

          <FormControl
            width={{ base: "100%", md: "40%" }}
            isRequired
            isInvalid={errors.phone ? true : false}
          >
            <FormLabel color={"brand.100"}>Téléphone</FormLabel>
            <Input
              type="tel"
              _hover={{
                border: "1px solid",
                borderColor: "brand.200",
                color: "brand.100",
              }}
              _focus={{
                border: "1px solid",
                borderColor: "brand.200",
                color: "brand.100",
              }}
              {...register("phone")}
              bg={"brand.400"}
              color={"brand.100"}
              required
            />
            <Text
              visibility={errors.phone ? "visible" : "hidden"}
              color={"red.500"}
            >
              {errors.phone ? errors.phone.message : "errorConfirmPaswword"}
            </Text>
          </FormControl>
        </Flex>

        <Flex
          mb={"3em"}
          direction={{ base: "column", md: "row" }}
          justifyContent={"space-around"}
          w={{ base: "90%", md: "80%" }}
        >
          <FormControl
            width={{ base: "100%", md: "40%" }}
            // mt={"1em"}
            // mb={"1em"}
            isRequired
            isInvalid={errors.message ? true : false}
          >
            <FormLabel color={"brand.100"}>Message</FormLabel>
            <Textarea
              // border={"none"}
              minHeight="200px"
              focusBorderColor="brand.200"
              _hover={{
                border: "1px solid",
                borderColor: "brand.200",
                color: "brand.100",
              }}
              _focus={{
                border: "1px solid",
                borderColor: "brand.200",
                color: "brand.100",
              }}
              placeholder="Saisissez votre message"
              required
              {...register("message")}
              bg={"brand.400"}
              color={"brand.100"}
            />
            <Text
              visibility={errors.message ? "visible" : "hidden"}
              color={"red.500"}
            >
              {errors.message ? errors.message.message : "errorPasswd"}
            </Text>
          </FormControl>

          <FormControl width={{ base: "100%", md: "40%" }}>
            <FormLabel color={"brand.100"}>
              Vos demandes et appels d'offres :
            </FormLabel>
            <MotionButton
              w={"100%"}
              onClick={handleClick}
              whileTap={{ scale: 0.85 }}
              // colorScheme="teal"
              bg={"brand.100"}
              mb={2}
            >
              Choisir un fichier
            </MotionButton>
            <Input
              type="file"
              ref={fileInputRef}
              onChange={handleFileChange}
              display="none"
            />
            {fileName && (
              <Text mt={2} fontWeight="bold">
                {fileName}
              </Text>
            )}
          </FormControl>
        </Flex>

        <MotionButton
          whileTap={{ scale: 0.85 }}
          type="submit"
          alignSelf={"center"}
          bg={"brand.100"}
          size={"lg"}
          width={250}
          // isLoading={isLoading}
          // isDisabled={isBtnDisabled()}
        >
          Envoyer
        </MotionButton>
      </Flex>
    </AnimatedPage>
  );
};

export default Contact;
