/**
 * @author Mathias MANOU
 * @email mathiasmanou@gmail.com
 * @description Footer component
 */

import { useContext } from "react";
import { Text, Flex, Link as ChakraLink } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { FaLinkedin } from "react-icons/fa6";

import { footerLinks } from "../helpers/constantes";
import { AppContext } from "../helpers/authProvider";

const footer = [
  {
    path: "https://www.linkedin.com/company/acad%C3%A9mie-internationale-de-la-bancassurance",
    comp: <FaLinkedin size={"3em"} />,
  },
];

const Footer = () => {
  const { state, dispatch } = useContext(AppContext);
  const { mobileMenuOpen } = state;

  const toggleMenu = () => {
    dispatch({ type: "MOBILEMENU", payload: !mobileMenuOpen });
  };

  return (
    <Flex
      as="footer"
      bg="brand.100"
      color="white"
      py={8}
      position="relative"
      bottom="0"
      width="100%"
      alignItems={{ md: "center" }}
      justifyContent={{ md: "space-around" }}
      direction={{ base: "column", md: "row" }}
      gap={{ base: "2em", md: "0em" }}
    >
      {footer.map((item) => {
        return (
          <ChakraLink key={item.path} href={item.path} isExternal>
            {item.comp}
          </ChakraLink>
        );
      })}

      {footerLinks.map((item, index) => {
        return (
          <Link
            color="teal.300"
            key={index}
            to={item.lien}
            onClick={toggleMenu}
          >
            <Text
              color="brand.500"
              fontFamily="'Montserrat Variable', sans-serif"
            >
              {item.name}
            </Text>
          </Link>
        );
      })}
      <Text color="brand.500" fontFamily="'Open Sans Variable', sans-serif">
        &copy; {new Date().getFullYear()} A.I.B
      </Text>
    </Flex>
  );
};

export default Footer;
