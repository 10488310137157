// constantes.tsx

export const menu = [
  {
    name: "Formations certifiantes",
    path: "/formationscertifiantes",
  },
  // {
  //   name: "Formations diplomantes",
  //   path: "/formationsdiplomantes",
  // },
  {
    name: "Coaching",
    path: "/coaching",
  },
  // { name: "Conseil", path: "/conseil" },
  {
    name: "Intervenants",
    path: "/intervenants",
  },
  { name: "Contact", path: "/contact" },
];

export const footerLinks = [
  // { lien: "/àpropos", name: "À propos" },
  // { lien: "/contact", name: "Contact" },
  { lien: "/politiquedeconfidentialité", name: "Politique de confidentialité" },
];

export const menuListe = [
  {
    name: "Formations diplomantes",
    subLinks: [
      { path: "/formationsdiplomantes", name: "Formations diplomantes" },
    ],
  },
  {
    name: "Formations internes",
    subLinks: [
      { name: "Formations", path: "/formationsinternes" },
      { path: "/formationsinternes/intervenants", name: "Intervenants" },
    ],
  },
  {
    name: "Coaching",
    subLinks: [{ path: "/coaching", name: "Coaching" }],
  },
  { name: "Conseil", subLinks: [{ path: "/conseil", name: "Expertises" }] },
  {
    name: "Conférences",
    subLinks: [{ path: "/conférences", name: "jsais pas SHN" }],
  },
  {
    name: "Intervenants",
    subLinks: [{ path: "/intervenants", name: "Intervenants" }],
  },
  // { name: "Contact" },
];

const conseilSkills = [
  "Vos enjeux",
  "Les grandes transformations du secteur",
  "L’évolution des métiers",
  "L’optimisation des modèles de distribution",
  "La transformation du modèle opérationnel",
  "La digitalisation des activités",
];

export const parSecteurCat = [
  { title: "Banque", skills: conseilSkills },
  { title: "Assurance", skills: conseilSkills },
  { title: "Microfinance", skills: conseilSkills },
];

export const parMetierCat = [
  {
    title: "Management",
    skills: [
      "Administrateur",
      "Membre de la Direction générale",
      "Manager de proximité",
    ],
  },
  {
    title: "Commerce",
    skills: [
      "Chargé d’accueil",
      "Conseiller de clientèle",
      "Directeur d’agence commerciale",
    ],
  },
  {
    title: "Conformité / Sécurité financière",
    skills: ["RCCI", "RCSI", "Chargé de conformité (généraliste & spécialisé)"],
  },
];
