/**
 * @author Mathias MANOU
 * @email mathiasmanou@gmail.com
 * @description Mobile drawer component
 */

import { useContext } from "react";
import {
  Flex,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
} from "@chakra-ui/react";

import { AppContext } from "../helpers/authProvider";
import Footer from "./Footer";
import Menu from "./Menu";

function DrawerMobile() {
  const { state, dispatch } = useContext(AppContext);
  const { mobileMenuOpen } = state;

  const toggleMenu = () => {
    dispatch({ type: "MOBILEMENU", payload: !mobileMenuOpen });
  };

  return (
    <>
      <Drawer isOpen={mobileMenuOpen} placement="left" onClose={toggleMenu}>
        <DrawerOverlay />
        <DrawerContent bg={"brand.100"}>
          <DrawerCloseButton />
          {/* <DrawerHeader>Menu</DrawerHeader> */}

          <DrawerBody gap={21} paddingTop={"5em"}>
            <Flex direction={"column"} h={"100%"}>
              <Menu />
              <Flex>
                <Footer />
              </Flex>
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

export default DrawerMobile;
