/**
 * @author Mathias MANOU
 * @email mathiasmanou@gmail.com
 * @description Loading page
 */

import { Center } from "@chakra-ui/react";
import { motion } from "framer-motion";

function Loading() {
  return (
    <Center height="100vh" bg="brand.100">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 1 }}>
        <svg width="3000" height="200" viewBox="0 0 3000 200">
          <text
            x="50%"
            y="50%"
            textAnchor="middle"
            dy=".35em"
            fontSize="48"
            fontWeight="bold"
            fill="white">
            Académie Internationale de la Bancassurance
          </text>
          <circle cx="20" cy="80" r="5" fill="teal">
            <animate
              attributeName="cy"
              values="80;20;80"
              dur="1s"
              repeatCount="indefinite"
              begin="0s"
            />
          </circle>
          <circle cx="40" cy="80" r="5" fill="teal">
            <animate
              attributeName="cy"
              values="80;20;80"
              dur="1s"
              repeatCount="indefinite"
              begin="0.2s"
            />
          </circle>
          <circle cx="60" cy="80" r="5" fill="teal">
            <animate
              attributeName="cy"
              values="80;20;80"
              dur="1s"
              repeatCount="indefinite"
              begin="0.4s"
            />
          </circle>
        </svg>
      </motion.div>
    </Center>
  );
}

export default Loading;
