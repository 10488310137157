/**
 * @author Mathias MANOU
 * @email mathiasmanou@gmail.com
 * @description authProvider.tsx
 */

import { createContext, useReducer, useEffect } from "react";
import { collection, DocumentData, onSnapshot } from "firebase/firestore";
import { AnimatePresence } from "framer-motion";

import { db } from "./firebase";
import Loading from "../pages/Loading";
import AnimatedPage from "../components/AnimatedPage";

const AppReducer = (appState: State, action: AppAction) => {
  const { type, payload } = action;

  switch (type) {
    case "MOBILEMENU":
      return {
        ...appState,
        mobileMenuOpen: payload,
      };
    case "MODAL":
      return {
        ...appState,
        modalOpen: payload,
      };

    case "MODALDATA":
      return {
        ...appState,
        modalData: payload,
      };

    case "INTERVENANTS":
      return {
        ...appState,
        intervenants: payload,
      };

    case "CONTACTS":
      return {
        ...appState,
        contacts: payload,
      };

    case "FILTEREDDATA":
      return {
        ...appState,
        filteredData: payload,
      };

    case "PENDING":
      return {
        ...appState,
        pending: payload,
      };

    default:
      return appState;
  }
};

const initialAppState = {
  user: null,
  pending: true,
  mobileMenuOpen: false,
  modalOpen: false,
  modalData: { data: [], title: "" },
  intervenants: [],
  // metiers: [],
  filteredData: [],
  // formations: [],
  contacts: [],
  // basket: [],
  // devis: {},
};

export const AppContext = createContext<{
  state: State;
  dispatch: React.Dispatch<AppAction>;
}>({ state: initialAppState, dispatch: () => undefined });

export const AppContextProvider = ({ children }: PadawanInter) => {
  const [state, dispatch] = useReducer(AppReducer, initialAppState);

  useEffect(() => {
    // const formations: DocumentData[] = [];
    const intervenants: DocumentData[] = [];
    // const metiers: DocumentData[] = [];
    /* onSnapshot(collection(db, "formations"), (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // console.log("formation:", doc.id, "=>", doc.data());
        formations.push({ ...{ id: doc.id }, ...doc.data() });
      });
    }); */
    /* onSnapshot(collection(db, "metiers"), (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // console.log("formation:", doc.id, "=>", doc.data());
        metiers.push({ ...{ id: doc.id }, ...doc.data() });
      });
    }); */
    onSnapshot(collection(db, "intervenants"), (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        // console.log("intervenant:", doc.id, "=>", doc.data());
        intervenants.push({ ...{ id: doc.id }, ...doc.data() });
      });
      // console.log("start");
      dispatch({ type: "PENDING", payload: false });
    });
    // dispatch({ type: "FORMATIONS", payload: formations });
    // dispatch({ type: "METIERS", payload: metiers });
    dispatch({ type: "INTERVENANTS", payload: intervenants });

    /* return () => {
      unsubscribe();
    }; */
  }, []);

  return (
    <AppContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      <AnimatePresence>
        {state.pending ? (
          <Loading key="loading" />
        ) : (
          <AnimatedPage>{children}</AnimatedPage>
        )}
      </AnimatePresence>
    </AppContext.Provider>
  );
};
