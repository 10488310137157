// Root.tsx

import { Box, Flex, Show } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import Footer from "./Footer";
import DrawerMobile from "./MobileDrawer";
import StickyHeader from "./StickyHeader";
import Modale from "./Modal";

const Root = () => {
  return (
    <Flex className="App" direction={"column"} minHeight="100vh">
      <StickyHeader />
      <Show below="md">
        <DrawerMobile />
      </Show>
      <Box
        padding={{
          base: "1em", // Padding pour les petits écrans (mobile)
          md: "2em", // Padding pour les écrans moyens et plus grands
        }}
        flex="1">
        <AnimatePresence mode="wait">
          <Outlet />
        </AnimatePresence>
      </Box>
      <Show above="md">
        <Footer />
      </Show>
      <Modale />
    </Flex>
  );
};

export default Root;
