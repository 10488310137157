/**
 * @author Mathias MANOU
 * @email mathiasmanou@gmail.com
 * @description helpers
 */

export const linkColor = (path: string, pathname: string): string => {
  // console.log({ path, pathname });
  return path === decodeURI(pathname) ? "brand.200" : "brand.500";
};
