// HomePage.tsx

import { Flex, Text, Show, Image } from "@chakra-ui/react";

import AnimatedPage from "../components/AnimatedPage";

const HomeDesktop = () => {
  return (
    <Flex gap={"2em"} width={"100%"} flex={1} direction={"row"}>
      <Show above="md">
        <Flex width={"25%"}>
          <Image
            borderRadius={"25"}
            src="https://firebasestorage.googleapis.com/v0/b/academie-c1882.firebasestorage.app/o/salleDeClasse.jpg?alt=media&token=ac073be4-af3e-4ca0-8b4b-20dfa13742c7"
            alt="Salle de classe"
          />
        </Flex>
      </Show>
      <Flex
        width={{ base: "100%", md: "50%" }}
        alignItems={"center"}
        flexDirection={"column"}
      >
        <Text color={"brand.100"}>
          Nous sommes un groupement d’experts spécialisé dans le secteur de la
          bancassurance en France et à l’International, constitué de
          professionnels aguerris et pédagogues, dédié à l’accompagnement des
          salariés des Banques, des Compagnies d’Assurance ainsi que des
          entreprises de Microfinance.<br></br>
          <br></br>
          Notre but est d’être à vos côtés afin de vous accompagner dans le
          cadre de la mise en œuvre de formations sur mesure, personnalisées et
          adaptées à vos besoins spécifiques dans votre environnement, pour vous
          aider à améliorer la montée en compétences professionnelles des
          membres de votre capital humain ; capital immatériel des entreprises
          trop souvent minimisé ou non-évalué.<br></br>
          <br></br>
          Nous savons répondre au travers de formations réellement sur-mesure
          aux exigences uniques de votre entreprise et de vos marchés.<br></br>
          <br></br>
          Nous nous engageons à développer les compétences professionnelles de
          vos équipes pour les préparer aux défis de demain.<br></br>
          <br></br>
          Nous collaborons étroitement avec vous pour co-construire vos plans
          pluriannuels de formation, assurant ainsi une croissance continue et
          durable de vos activités.<br></br>
          <br></br>
          En choisissant l’Académie Internationale de la Bancassurance, vous
          optez pour un partenaire engagé à vos côtés dans votre succès car nous
          croyons fermement que la formation est un levier essentiel pour le
          développement de vos talents et la performance de votre entreprise.
          <br></br>
          <br></br>
          Notre engagement d’application est de vous inviter à oser transformer
          la formation en « form’action » !<br></br>
          <br></br>
          Ensemble, nous construirons un avenir où compétences et performance
          vont de pair.<br></br>
          <br></br>
          Contactez-nous dès aujourd’hui pour découvrir comment nous pouvons
          vous accompagner dans la construction de vos parcours de formation et
          de développement professionnel.<br></br>
          <br></br>
        </Text>
        <Text color={"brand.100"} width={"100%"} textAlign={"end"}>
          <br></br>Stéphane VROT<br></br>
        </Text>
        <Text color={"brand.100"} width={"100%"} textAlign={"end"}>
          Président-Fondateur
        </Text>
      </Flex>
      <Show above="md">
        <Flex width={"25%"} flexDirection={"column"} gap={5}>
          <Image
            borderRadius={"25px"}
            src="https://firebasestorage.googleapis.com/v0/b/academieib.appspot.com/o/Photo%20profil_05.10.2021.jpg?alt=media&token=9baf1683-1a69-41f3-87e5-b292b98df729"
            alt="Patron"
          />
          {/* <Image
            borderRadius={"25"}
            src="https://firebasestorage.googleapis.com/v0/b/academieib.appspot.com/o/eleves.jpg?alt=media&token=cc6a1ae8-e14a-4c4d-98d3-8f545d0e3326"
            alt="Eléves"
          /> */}
        </Flex>
      </Show>
    </Flex>
  );
};

const Home = () => {
  return (
    <AnimatedPage>
      <Flex
        width={"100%"}
        h={"100%"}
        direction={"column"}
        alignItems={"center"}
      >
        <HomeDesktop />
      </Flex>
    </AnimatedPage>
  );
};

export default Home;
