/**
 * @author Mathias MANOU
 * @email mathiasmanou@gmail.com
 * @description StickyHeader component
 */

import { useContext } from "react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { Flex, IconButton, Show, Text, Image } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

import logo from "../assets/logo.svg";
import logoBlanc from "../assets/logoBlanc.svg";
import { AppContext } from "../helpers/authProvider";
import Menu from "./Menu";

const MotionButton = motion(IconButton);

const Logo = () => {
  return (
    <nav>
      <Link to={"/"}>
        <Image src={logoBlanc} alt="" height={100} width={250} />
      </Link>
    </nav>
  );
};

const StickyHeader = () => {
  const { state, dispatch } = useContext(AppContext);
  const { mobileMenuOpen } = state;

  const toggleMenu = () => {
    dispatch({ type: "MOBILEMENU", payload: !mobileMenuOpen });
  };

  return (
    <Flex
      boxShadow="md"
      // direction={"column"}
      bg={"brand.100"}
      as="header"
      position="sticky"
      top="0"
      zIndex="sticky"
      p={4}
      // gap={"1em"}
      // alignItems={"center"}
      justifyContent={{ md: "space-around" }}
      // justifyContent={"space-between"}
    >
      <Flex
        // bg={"purple"}
        // flex={1}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Logo />
      </Flex>

      <Flex
        flex={1}
        flexDirection={"column"}
        alignItems={{ base: "end", md: "center" }}
      >
        <Show above="md">
          <Text
            fontSize={"2.5em"}
            fontFamily="'Montserrat Variable', sans-serif"
          >
            Académie Internationale de la Bancassurance
          </Text>
        </Show>
        <Show below="md">
          <MotionButton
            whileTap={{ scale: 0.85 }}
            aria-label="Menu"
            icon={<HamburgerIcon />}
            onClick={toggleMenu}
          />
        </Show>
        <Show above="md">
          <Menu />
        </Show>
      </Flex>
    </Flex>
  );

  /* return (
    <Flex
      boxShadow="md"
     // direction={"column"}
      bg={"brand.100"}
      as="header"
      position="sticky"
      top="0"
      zIndex="sticky"
      p={4}
      // gap={"1em"}
      alignItems={"center"}
      justifyContent={"space-between"}>
      <Flex
        // bg={"purple"}
        flex={1}
        justifyContent={"space-between"}
        alignItems={"center"}
        w={"100%"}>
        <Logo />
        <Show above="md">
          <Text
            fontSize={"2.5em"}
            fontFamily="'Montserrat Variable', sans-serif">
            Académie Internationale de la Bancassurance
          </Text>
        </Show>
        <Flex gap={6}>
          <MotionButton
            whileTap={{ scale: 0.85 }}
            as={Link}
            to={"/panier"}
            variant="solid"
            colorScheme="gray"
            aria-label="Shopping Cart"
            icon={<FiShoppingCart />}
            display={"none"}
          />
          <Show below="md">
            <MotionButton
              whileTap={{ scale: 0.85 }}
              aria-label="Menu"
              icon={<HamburgerIcon />}
              onClick={toggleMenu}
            />
          </Show>
        </Flex>
      </Flex>
      <Flex w={"100%"} justifyContent={"center"}>
        <Show above="md">
          <Menu />
        </Show>
      </Flex>
    </Flex>
  ); */
};

export default StickyHeader;
