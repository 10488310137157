import { extendTheme } from "@chakra-ui/react";
import "@fontsource-variable/roboto-mono";
import "@fontsource-variable/montserrat";
import "@fontsource/lato";
import "@fontsource-variable/open-sans";

const colors = {
  brand: {
    100: "#002855", // bleu
    200: "#FF7F00", // orange vif
    300: "#28A745", // vert
    400: "#F0F0F0", // gris clair
    500: "#FAF9F6", // blanc cassé
    600: "#FFD700", // or
  },
};

export const theme = extendTheme({
  colors,
  fonts: {
    heading: `'Montserrat Variable', sans-serif`,
    body: `'Lato', sans-serif`,
  },
  fontWeights: {
    normal: 400, // Poids normal
    bold: 700, // Poids gras (bold)
  },
  // Personnaliser les composants
  components: {
    Card: {
      baseStyle: {
        bg: "brand.400", // Utilisez la clé de couleur personnalisée
        colors: "brand.100",
      },
    },
    /*  Input: {
      variants: {
        outline: {
          field: {
            _focus: {
              borderColor: "teal.300",
              boxShadow: "none",
            },
          },
        },
      },
    }, */
    Textarea: {
      baseStyle: {
        field: {
          _focus: {
            borderColor: "pink",
          },
        },
      },
      variants: {
        outline: {
          field: {
            _focus: {
              borderColor: "teal.300",
              boxShadow: "none",
            },
          },
        },
      },
    },
    Button: {
      defaultProps: {
        colorScheme: "orange",
      },
    },
  },
  styles: {
    global: {
      /* fonts: {
        heading: "Roboto, sans-serif",
        body: "Roboto, sans-serif",
        mono: "Roboto, monospace",
      }, */
      // styles for the `body`
      body: {
        color: "white",
        background: "brand.500",
      },
    },
  },
});

// console.log(theme.components); //

export default theme;
