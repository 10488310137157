/**
 * @author Mathias MANOU
 * @email mathiasmanou@gmail.com
 * @description Coaching page
 */

import { Flex, Text } from "@chakra-ui/react";

import AnimatedPage from "../components/AnimatedPage";
import { CoachingTecar } from "../components/cards";

const Coaching = () => {
  return (
    <AnimatedPage>
      <Flex
        justifyContent={"space-between"}
        flexDirection={{ base: "column", md: "row" }}
      >
        <Flex w={{ md: "30%" }} flexDir={"column"}>
          <CoachingTecar
            item={{
              Thématique: "Suivi post formations",
            }}
          />
          <Text padding={"1em"} color={"brand.100"}>
            Phase essentielle dans le cadre de la mise en œuvre d’un dispositif
            de formation s’inscrivant dans la continuité des engagements
            d’application pris par les apprenants ayant participé à l’un de nos
            séminaires coconstruits.<br></br>
            <br></br>Cette étape consiste en :
            <br></br>
            <br></br>- Un accompagnement et une levée des éventuels freins des
            participants à la mise en pratique quotidienne des compétences
            professionnelles développées en formation
            <br></br>
            <br></br>- Un suivi et une évaluation des évolutions en termes de
            mesure de la performance post-formation ; aux côtés du manager de
            proximité
            <br></br>
            <br></br>- Une dynamique d’amélioration vertueuse et continue de la
            pertinence des contenus dispensés dans le cadre de votre plan
            pluriannuel de formation
          </Text>
        </Flex>
        <Flex w={{ md: "30%" }} flexDir={"column"}>
          <CoachingTecar
            item={{
              Thématique: "Prise de parole en public et média training",
            }}
          />

          <Text padding={"1em"} color={"brand.100"}>
            A destination plus spécifique des managers et membres des organes de
            gouvernance de votre Etablissement, la maîtrise d’une communication
            efficace optimise la bonne diffusion des messages stratégiques et
            participe à l’atteinte tant des objectifs collectifs que de la
            réalisation du niveau de performance attendu.<br></br>
            <br></br>Ces formations uniques appellent à :
            <br></br>
            <br></br>- Découvrir les atouts naturels de chaque collaborateur et
            les déployer pour convaincre
            <br></br>
            <br></br>- Mettre en cohérence le message et la manière (verbale,
            non-verbale, para-verbale) de l’exprimer
            <br></br>
            <br></br>- Synchroniser le discours et l’attitude
          </Text>
        </Flex>
        <Flex w={{ md: "30%" }} flexDir={"column"}>
          <CoachingTecar
            item={{
              Thématique: "Préparation au TOEIC",
            }}
          />
          <Text padding={"1em"} color={"brand.100"}>
            La langue anglaise est bien souvent un « must have » pour que chaque
            collaborateur, et notamment ceux membres de la Direction, participe
            efficacement à la réalisation de l’objet social de leur entreprise.
            <br></br>
            <br></br>
            Les membres de votre capital humain devraient ainsi être à même de
            comprendre et d’échanger des informations rapidement tant à l’écrit
            qu’à l’oral avec l’ensemble de leurs interlocuteurs internes (siège,
            hiérarchie, collaborateurs) et externes (clients, investisseurs,
            médias) potentiels.<br></br>
            <br></br>Notre accompagnement spécifique vise ainsi à :<br></br>
            <br></br>- Mesurer les compétences actuelles de compréhension écrite
            et orale de vos collaborateurs
            <br></br>
            <br></br>- Déterminer un plan de formation sur-mesure afin de
            renforcer les compétences nécessaires et accompagner la progression
            individuelle
            <br></br>
            <br></br>- Entraîner à la réalisation de l’épreuve écrite et orale
          </Text>
        </Flex>
      </Flex>
    </AnimatedPage>
  );
};

export default Coaching;
