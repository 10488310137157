/**
 * @author Mathias MANOU
 * @email mathiasmanou@gmail.com
 * @description Menu component
 */

import { Flex, Text } from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { useContext } from "react";

import { menu } from "../helpers/constantes";
import { linkColor } from "../helpers/formules";
import { AppContext } from "../helpers/authProvider";

const Menu = () => {
  const { pathname } = useLocation();
  const { state, dispatch } = useContext(AppContext);
  const { mobileMenuOpen } = state;

  const toggleMenu = () => {
    dispatch({ type: "MOBILEMENU", payload: !mobileMenuOpen });
  };

  return (
    <Flex
      // bg={"red"}
      flex={{ base: 1, md: "none" }}
      direction={{ base: "column", md: "row" }}
      alignItems={{ md: "center" }}
      justifyContent={{ md: "space-around" }}
      py={{ md: 2 }}
      gap={{ base: 21, md: "2em" }}
    >
      {menu.map((item) => {
        const { path, name } = item;
        return (
          <Link to={path} key={item.path} onClick={toggleMenu}>
            <Text
              fontSize={"1.3em"}
              color={linkColor(path, pathname)}
              fontFamily="'Montserrat Variable', sans-serif"
            >
              {name}
            </Text>
          </Link>
        );
      })}
    </Flex>
  );
};

export default Menu;
