/**
 * @author Mathias MANOU
 * @email mathiasmanou@gmail.com
 * @description Cards components
 */

import {
  Text,
  Card,
  Avatar,
  CardBody,
  CardHeader,
  Flex,
  Heading,
} from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import { useContext } from "react";

import { AppContext } from "../helpers/authProvider";

export const CoachingTecar = ({ item }: any) => {
  return (
    <Card
      // cursor={"pointer"}
      backgroundColor={"brand.100"}
      color={"brand.500"}
      w={"100%"}
    >
      <CardBody textAlign={"center"}>
        <Text size="sm" fontFamily="'Montserrat Variable', sans-serif">
          {item["Thématique"]}
        </Text>
      </CardBody>
    </Card>
  );
};

export const Tecar = ({ item }: any) => {
  const { state, dispatch } = useContext(AppContext);
  const { modalOpen } = state;
  const libelles = item["Libellé"];

  const toggleModal = () => {
    dispatch({
      type: "MODALDATA",
      payload: { data: libelles, title: item["Thématique"] },
    });
    dispatch({ type: "MODAL", payload: !modalOpen });
  };

  return (
    <Card
      cursor={"pointer"}
      backgroundColor={"brand.100"}
      color={"brand.500"}
      onClick={toggleModal}
    >
      <CardBody textAlign={"center"}>
        <Heading size="sm" color={"brand.300"} fontWeight={"bold"}>
          {item["Thématique"]}
        </Heading>
      </CardBody>
    </Card>
  );
};

export const IntervenantCard = ({ item }: any) => {
  const { state, dispatch } = useContext(AppContext);
  const { modalOpen } = state;

  const toggleModal = () => {
    dispatch({
      type: "MODALDATA",
      payload: {
        data: item?.skills,
        title: `${item.firstname} ${item.lastname}`,
      },
    });
    dispatch({ type: "MODAL", payload: !modalOpen });
  };

  return (
    <Card
      cursor={"pointer"}
      onClick={toggleModal}
      maxW="md"
      bg={"brand.100"}
      color={"white"}
    >
      <CardHeader display={"flex"} gap={4}>
        <Avatar
          size="xl"
          name={`${item.firstname} ${item.lastname}`}
          src={item?.tof}
        />

        <Flex flexDir={"column"}>
          <Heading size="sm" color={"brand.300"}>
            {item.firstname} {item.lastname}
          </Heading>
          <Text>{item?.diplomes}</Text>
        </Flex>
      </CardHeader>
    </Card>
  );
};

export const Carte = ({ item }: any) => {
  const skills = item.skills;

  return (
    <Card bg={"brand.100"}>
      <CardHeader>
        <Heading color={"brand.300"} size="sm">
          {item.title}
        </Heading>
      </CardHeader>
      <CardBody>
        {skills.map((item: any, index: number) => {
          return (
            <Text key={index} color={"brand.500"}>
              <CheckIcon />
              {` ${item}`}
            </Text>
          );
        })}
      </CardBody>
    </Card>
  );
};
