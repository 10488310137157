/**
 * @author Mathias MANOU
 * @email mathiasmanou@gmail.com
 * @description Intervenants page
 */

import { Text, SimpleGrid } from "@chakra-ui/react";
import { useEffect, useContext } from "react";
import AnimatedPage from "../components/AnimatedPage";
import { AppContext } from "../helpers/authProvider";
import FilterBar from "../components/FilterBar";
import { IntervenantCard } from "../components/cards";

const Intervenants = () => {
  const { state } = useContext(AppContext);
  const { intervenants, pending } = state;
  intervenants.sort((a: any, b: any) => a.lastname.localeCompare(b.lastname));

  // console.log({ intervenants });

  useEffect(() => {}, [pending, intervenants]);

  return (
    <AnimatedPage>
      <FilterBar data={intervenants}>
        {(filteredData) => {
          return filteredData.length > 0 ? (
            <SimpleGrid pt={"1em"} spacing="40px" columns={{ base: 1, md: 3 }}>
              {filteredData.map((item: any, index: number) => {
                return <IntervenantCard item={item} key={index} />;
              })}
            </SimpleGrid>
          ) : (
            <Text color="gray.500">Aucun élément trouvé</Text>
          );
        }}
      </FilterBar>
    </AnimatedPage>
  );
};

export default Intervenants;
